<template>
  <section class="full-height">

    <div class="columns is-centered is-gapless is-marginless">
      <div class="column is-mobile is-narrow mt2rem mb1rem">
        <div class="has-text-grey is-size-2 is-size-4-mobile wa-underlined-title">Email Filter</div>
      </div>
    </div>
    
    
    <div>
      <div class="columns is-multiline is-centered is-gapless is-marginless">
        
        <b-field label="Emails to check" class="column is-4 is-offset-2 m1rem">
          <b-input maxlength="20000000" v-model="unfilteredList" type="textarea" custom-class="inputheight1" @input="filterEmailListInput"></b-input>
        </b-field> 
        
        <div class="column is-4 m1rem">
          <div label="Result:" >
            <label class="label">Invalid: {{ invalidEmails.length }} Emails</label>
            <b-button v-if="invalidEmails.length > 0" @click="copyInValidEmails">copy</b-button>
            <b-input maxlength="20000000" type="textarea" disabled custom-class="inputheight3" :value="invalidEmails.join(`\r\n`)"> 
            </b-input>
          </div>    
          
          <div label="Result:">
            <label class="label">Valid: {{ validEmails.length }} Emails</label>
            <b-button v-if="validEmails.length > 0" @click="copyValidEmails">copy</b-button>
            <b-input maxlength="20000000" type="textarea" disabled custom-class="inputheight1" :value="validEmails.join(`\r\n`)"> 
            </b-input>
            
          </div>    
        </div>
               
        
      </div>
      
    </div>
    
       
    
    <b-notification :closable="false">
      <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
    </b-notification>
  </section>
</template>

<script>

// import { Auth }             from '@/plugins/firebase/auth';
// import { DB }               from '@/plugins/firebase/db';
// import axios_clean          from 'axios';
// import axios_api from '@/plugins/axios_api';
// import { API_ROOT } from '@/config/app.js';

// import { Calc_TimeLeftMsg }         from '@/helpers/functions';
// import { API_ROOT } from '@/config/app.js';
// import { mapState } from 'vuex';

const EmailValidator = require("email-validator");

export default {
  name: "EmptyPage", 
  
         
  data() {
    return {
      pageLoading         : false,
      pageLoadingTimeout  : null,
      
      unfilteredList      : ``,
      invalidEmails       : [],
      validEmails         : [],
    }
  },
  
  async mounted(){
   // this.ShowPageLoading(15000);
    // this.UpdateLockStatus();
    // // this.GetTableStructureData();
    
    // const _this = this;
    // setInterval(() => {
    //   _this.CheckLockStatus();
    // }, 1000);
    
  },
  
  methods: {
      
    // async UpdateLockStatus() {
    //   const LockStatus = await this.$store.dispatch('databaseactions/DB_GetLockStatus');      
    //   // console.info(`LockStatus:`, LockStatus);
    //   this.SetUnlockStatus(LockStatus);
    // },   
     
    async ShowPageLoading(maxDuration = 30000){
      this.pageLoading = true;
      
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
      
      this.pageLoadingTimeout = setTimeout(() => {
        this.HidePageLoading();
      }, maxDuration);
    },
    
    async HidePageLoading() {
      this.pageLoading = false;
      if (this.pageLoadingTimeout !== null) {
        clearTimeout(this.pageLoadingTimeout);
      }
    },
    
    async filterEmailListInput() {
      let content = this.unfilteredList
            
      content = content.split('\t').join('\n')
      content = content.split(' ').join('\n')
      content = content.split('\r').join('\n')
      content = content.split('<').join('\n')
      content = content.split('>').join('\n')
      content = content.split(';').join('\n')
      content = content.split(',').join('\n')
      content = content.split(' ').join('\n')
      content = content.split('|').join('\n')
      content = content.split('{').join('\n')
      content = content.split('}').join('\n')
      content = content.split(':').join('\n')
      content = content.split('\'').join('\n')
      content = content.split('`').join('\n')
      content = content.split('[`').join('\n')
      content = content.split(']`').join('\n')
      content = content.split('\\').join('\n')
      content = content.split('!').join('\n')
      content = content.split('#').join('\n')
      content = content.split('$').join('\n')
      content = content.split('%').join('\n')
      content = content.split('^').join('\n')
      content = content.split('&').join('\n')
      content = content.split('*').join('\n')
      content = content.split('(').join('\n')
      content = content.split(')').join('\n')
      content = content.split('=').join('\n')
      content = content.split('~').join('\n')
      content = content.split('?').join('\n')
      
      const items = content.split('\n');
      
      let accepted = [];
      let rejected = [];
      let emails = {}
      for(let row of items) {
        if (row !== row.trim()) {
          rejected.push(`email trimmed, but accepted. invalid otherwise: ${row}`)
          row = row.trim()
        }
        
        if (row === '') continue
        
        // console.info(`row: -${row}-`)
        if (!EmailValidator.validate(row)) {
          rejected.push(`Invalid email: ${row}`)
        } else if (typeof(emails[row]) !== "undefined"){
          rejected.push(`Duplicate email: ${row}`)
        } else {
          accepted.push(row)
          emails[row] = true
        }
      }
      
      this.validEmails = accepted;
      this.invalidEmails = rejected;
    },
    
    copyValidEmails(){
      this.copyToClipboard(this.validEmails.join(`\n`))
    },
    copyInValidEmails(){
      this.copyToClipboard(this.invalidEmails.join(`\n`))
    },
    
    async copyToClipboard(text){
      const _this = this;
      navigator.clipboard.writeText(text).then(function() {
      _this.$buefy.toast.open('copied to clipboard')
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
      _this.$buefy.toast.open('ERROR copying to clipboard!')
    });
    }
    
    
  },
};
</script>

<style>
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight1{
      /* background-color: red; */
      font-size: 14px;
      height: 600px;
    }
  
    .inputheight2{
      /* background-color: red; */
      height: 560px;
      font-size: 11px;
    }
    
    .MyButton{
      /* background-color: red; */
      width: 96%;
      margin-left: 2%;
      margin-top: 2%;
    }
    
    textarea {
      resize: none;
    }
    
    .editor {
      width: 94%;
      margin-left: 3%;
      height: 1000px;
    }
  
    .inputheight1{
      /* background-color: red; */
      font-size: 14px;
      height: 600px;
    }
    
    .inputheight2{
      /* background-color: red; */
      font-size: 14px;
      height: 300px;
    }
    
    .inputheight3{
      /* background-color: red; */
      font-size: 14px;
      height: 50px;
    }
    
    .inputheight4{
      /* background-color: red; */
      font-size: 14px;
      height: 150px;
    }
    .spacer{
      height: 50px;
      width: 100%;
    }
    
    .example_messages p{
      margin-top: 15px;
      padding: 2%;
    }
  

</style>